<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <template v-if="activeKey === 2">
              <a-col :md="6" :sm="24">
                <a-form-item label="设备编号" prop="deviceId">
                  <a-input v-model="queryParam.deviceId" placeholder="请输入设备编号" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item label="身份证号码" prop="identityCard">
                  <a-input v-model="queryParam.identityCard" placeholder="请输入身份证号码" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item label="姓名" prop="name">
                  <a-input v-model="queryParam.name" placeholder="请输入姓名" allow-clear />
                </a-form-item>
              </a-col>
            </template>
            <template v-else>
              <a-col :md="6" :sm="24">
                <a-form-item label="设备编号" prop="deviceId">
                  <a-input v-model="queryParam.deviceId" placeholder="请输入设备编号" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item label="车辆编号" prop="vehicleNo">
                  <a-select
                    show-search
                    allowClear
                    placeholder="请输入车辆自编号"
                    :default-active-first-option="false"
                    :show-arrow="false"
                    :filter-option="false"
                    :not-found-content="null"
                    @search="handleVehicleNoSearch"
                    @change="handleVehicleNoChange"
                    :loading="loading"
                    v-model="queryParam.vehicleNo"
                  >
                    <a-select-option v-for="d in vehicleNoArray" :key="d.vehicleNo" :value="d.vehicleNo">
                      {{ d.vehicleNo }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item label="车牌号" prop="plateNo">
                  <a-select
                    show-search
                    allowClear
                    placeholder="请输入车辆车牌号"
                    :default-active-first-option="false"
                    :show-arrow="false"
                    :filter-option="false"
                    :not-found-content="null"
                    @search="handleLicensePlateNumSearch"
                    @change="handleLicensePlateNumChange"
                    :loading="loading"
                    v-model="queryParam.plateNo"
                  >
                    <a-select-option v-for="d in vehicleNoArray" :key="d.licensePlateNum" :value="d.licensePlateNum">
                      {{ d.licensePlateNum }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </template>

            <a-col :md="(!advanced && 6) || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
              >
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <a-toolbar :loading="loading" refresh @refreshQuery="getList" :tid="activeKey + ''">
        <div class="table-operations">
          <a-button
            type="primary"
            @click="$refs.createForm.handleAdd(activeKey)"
            v-hasPermi="['tenant:jt808:VehicleBind:all']"
            v-if="activeKey === 1"
          >
            <a-icon component="carIcon" /> 车辆设备绑定
          </a-button>
          <a-button
            type="primary"
            @click="$refs.createForm.handleAdd(activeKey)"
            v-hasPermi="['tenant:jt808:EmployeeBind:all']"
            v-if="activeKey === 2"
          >
            <a-icon type="user-add" /> 人员设备绑定
          </a-button>
          <a-button
            type="primary"
            @click="$refs.createForm.handleAdd(3)"
            v-hasPermi="['tenant:jt808:EmployeeBind:all']"
            v-if="activeKey === 2"
          >
            <a-icon type="user-add" /> 人员小程序绑定
          </a-button>
          <!-- <a-button type="primary" @click="handleExport" v-hasPermi="['tenant:iot:deviceEmployee:export']"> <a-icon type="download" />导出 </a-button> -->
          <!-- <a-button
            type="dashed"
            shape="circle"
            :loading="loading"
            :style="{ float: 'right' }"
            icon="reload"
            @click="getList"
          /> -->
        </div>
      </a-toolbar>
      <!-- 增加修改 -->
      <create-form ref="createForm" @ok="getList" />
      <!-- 数据展示 -->
      <a-tabs v-model="activeKey">
        <a-tab-pane :key="1" tab="车辆" v-if="hasPermiVehicleBind">
          <device-vehicle ref="deviceVehicleRef" />
        </a-tab-pane>
        <a-tab-pane :key="2" tab="人员" v-if="hasPermiEmployeeBind">
          <device-employee ref="deviceEmployeeRef" />
        </a-tab-pane>
      </a-tabs>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import allIcons from '@/core/icons'
import debounce from 'lodash/debounce'
import CreateForm from './modules/CreateForm'
import DeviceEmployee from './modules/deviceEmployee'
import DeviceVehicle from './modules/deviceVehicle'
import { Tabs } from 'ant-design-vue'
import { searchCarNo } from '@/api/iot/vehicleAccount'
export default {
  name: 'DeviceBind',
  components: {
    CreateForm,
    DeviceEmployee,
    DeviceVehicle,
    ATabs: Tabs,
    ATabPane: Tabs.TabPane
  },
  data() {
    this.handleVehicleNoSearch = debounce(this.handleVehicleNoSearch, 500)
    this.handleLicensePlateNumSearch = debounce(this.handleLicensePlateNumSearch, 500)
    return {
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        deviceId: null,
        identityCard: null,
        vehicleNo: null,
        plateNo: null,
        status: null,
        tenantId: null,
        pageNum: 1,
        pageSize: 10
      },
      activeKey: 1,
      vehicleNoArray: [],
      carIcon: allIcons['carIcon']
    }
  },
  filters: {},
  created() {
    if (!this.hasPermiVehicleBind) {
      this.activeKey = 2
    }
  },
  computed: {
    // 车辆绑定权限
    hasPermiVehicleBind() {
      console.log('hasPermiVehicleBind')
      let hasPermit = true
      const value = ['tenant:jt808:VehicleBind:all']
      const allPermission = '*:*:*'
      const permissions = this.$store.getters && this.$store.getters.permissions
      if (value && value instanceof Array && value.length > 0) {
        const permissionFlag = value
        const hasPermissions = permissions.some((permission) => {
          return allPermission === permission || permissionFlag.includes(permission)
        })
        if (!hasPermissions) {
          hasPermit = false
        }
      } else {
        hasPermit = false
      }
      console.log('hasPermiVehicleBind', hasPermit)
      return hasPermit
    },

    // 人员绑定权限
    hasPermiEmployeeBind() {
      console.log('hasPermiEmployeeBind')
      let hasPermit = true
      const value = ['tenant:jt808:EmployeeBind:all']
      const allPermission = '*:*:*'
      const permissions = this.$store.getters && this.$store.getters.permissions
      if (value && value instanceof Array && value.length > 0) {
        const permissionFlag = value
        const hasPermissions = permissions.some((permission) => {
          return allPermission === permission || permissionFlag.includes(permission)
        })
        if (!hasPermissions) {
          hasPermit = false
        }
      } else {
        hasPermit = false
      }
      console.log('hasPermiEmployeeBind', hasPermit)
      return hasPermit
    }
  },
  watch: {},
  methods: {
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      if (this.activeKey === 2) {
        this.$refs.deviceEmployeeRef.getList(this.queryParam)
      } else {
        this.$refs.deviceVehicleRef.getList(this.queryParam)
      }
    },
    getList() {
      this.handleQuery()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        deviceId: undefined,
        identityCard: undefined,
        status: undefined,
        tenantId: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    handleVehicleNoSearch(value, fn) {
      console.log('handleVehicleNoSearch', value)
      let queryParam = {
        vehicleNo: value
      }
      if (value && !value.trim()) {
        queryParam = {
          pageNum: 1,
          pageSize: 10
        }
      }
      this.loading = true
      searchCarNo(queryParam).then((response) => {
        this.vehicleNoArray = response
        this.loading = false
        this.$forceUpdate()
        fn && fn(value)
      })
    },
    handleLicensePlateNumSearch(value) {
      console.log('handleLicensePlateNumSearch', value)
      let queryParam = {
        licensePlateNum: value
      }
      if (value && !value.trim()) {
        queryParam = {
          pageNum: 1,
          pageSize: 10
        }
      }
      this.loading = true
      searchCarNo(queryParam).then((response) => {
        this.vehicleNoArray = response
        this.loading = false
        this.$forceUpdate()
      })
    },
    handleVehicleNoChange(value) {
      const val = value === undefined ? '' : value
      this.queryParam.vehicleNo = val
      if (val === '') {
        this.queryParam.licensePlateNum = ''
      }
      // 设置车牌号
      const tmpArray = this.vehicleNoArray.filter((p) => p.vehicleNo === val)
      if (tmpArray.length > 0) {
        this.queryParam.plateNo = tmpArray[0].licensePlateNum
        this.$forceUpdate()
      }
    },
    handleLicensePlateNumChange(value) {
      const val = value === undefined ? '' : value
      this.queryParam.plateNo = val
      if (val === '') {
        this.queryParam.plateNo = ''
      }
      // 设置车辆自编号
      const tmpArray = this.vehicleNoArray.filter((p) => p.licensePlateNum === val)
      if (tmpArray.length > 0) {
        this.queryParam.vehicleNo = tmpArray[0].vehicleNo
        this.$forceUpdate()
      }
    }
  }
}
</script>
